import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/popper.js.org/popper.js.org/src/components/Layout.js";
import { Result1, Result2, Result3, Result4 } from '../../../components/Tutorial';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "tutorial",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#tutorial",
        "aria-label": "tutorial permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tutorial`}</h1>
    <p>{`In this tutorial you'll learn how to use Popper by building a basic tooltip.`}</p>
    <p>{`Remember, Popper is not a tooltip library, it's the foundation to build one!
Follow the tutorial below to learn how to use Popper.`}</p>
    <x-ad />
    <h2 {...{
      "id": "setting-up",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#setting-up",
        "aria-label": "setting up permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setting up`}</h2>
    <p>{`Create a new HTML document with two elements, a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<button>`}</code>{` and a tooltip
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<div>`}</code>{`, and pass them to Popper:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token doctype"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<!`}</span><span parentName="span" {...{
              "className": "token doctype-tag"
            }}>{`DOCTYPE`}</span>{` `}<span parentName="span" {...{
              "className": "token name"
            }}>{`html`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`html`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`head`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`title`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Popper Tutorial`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`title`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`head`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`body`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`button`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`button`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`aria-describedby`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`tooltip`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`My button`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`button`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`tooltip`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`role`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`tooltip`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`My tooltip`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`script`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://unpkg.com/@popperjs/core@2`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token script"
          }}></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token script"
          }}><span parentName="span" {...{
              "className": "token language-javascript"
            }}>{`
      `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`const`}</span>{` button `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` document`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`querySelector`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`'#button'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
      `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`const`}</span>{` tooltip `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` document`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`querySelector`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`'#tooltip'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`

      `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`const`}</span>{` popperInstance `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` Popper`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`createPopper`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`button`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` tooltip`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
    `}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`body`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`html`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <h2 {...{
      "id": "styling",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#styling",
        "aria-label": "styling permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Styling`}</h2>
    <p>{`Let's give our tooltip some styling:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token doctype"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<!`}</span><span parentName="span" {...{
              "className": "token doctype-tag"
            }}>{`DOCTYPE`}</span>{` `}<span parentName="span" {...{
              "className": "token name"
            }}>{`html`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`html`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`head`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`title`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Popper Tutorial`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`title`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`style`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token style"
          }}><span parentName="span" {...{
              "className": "token language-css"
            }}>{`
      `}<span parentName="span" {...{
                "className": "token selector"
              }}>{`#tooltip`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`background`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` #333`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`color`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` white`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`font-weight`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` bold`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`padding`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` 4px 8px`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`font-size`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` 13px`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`border-radius`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` 4px`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
      `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{`
    `}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`style`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`head`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`body`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`<!-- ... -->`}</span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`body`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`html`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`Here's the result so far:`}</p>
    <Result1 mdxType="Result1" />
    <h2 {...{
      "id": "arrow",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#arrow",
        "aria-label": "arrow permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Arrow`}</h2>
    <p>{`Our tooltip is currently just a box. In many UI design systems, this is all
tooltips need, but others prefer to have an arrow that points toward the
reference element.`}</p>
    <p>{`Add an arrow element with a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`data-popper-arrow`}</code>{` attribute like so:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`tooltip`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`role`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`tooltip`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  My tooltip
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`arrow`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`data-popper-arrow`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`Now for styling:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`#arrow,
#arrow::before`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 8px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 8px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` inherit`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`#arrow`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`visibility`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` hidden`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`#arrow::before`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`visibility`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` visible`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`content`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rotate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`45deg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`::before`}</code>{` pseudo-element is required because Popper uses `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`transform`}</code>{` to
position the arrow inside the popper, but we want to use our own `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`transform`}</code>{` to
rotate the arrow box into a diamond.`}</p>
    <p>{`Now we need to offset the arrow depending on the popper's current placement.
Popper provides this information with the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`data-popper-placement`}</code>{` attribute:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`#tooltip[data-popper-placement^='top'] > #arrow`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` -4px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`#tooltip[data-popper-placement^='bottom'] > #arrow`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` -4px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`#tooltip[data-popper-placement^='left'] > #arrow`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`right`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` -4px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`#tooltip[data-popper-placement^='right'] > #arrow`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` -4px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <blockquote>
      <p parentName="blockquote">{`Why the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`^=`}</code>{`? This means "starts with", because we can also have variation
placements like `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`top-start`}</code>{`.`}</p>
    </blockquote>
    <p>{`Here's the result so far:`}</p>
    <Result2 mdxType="Result2" />
    <h2 {...{
      "id": "offset",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#offset",
        "aria-label": "offset permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Offset`}</h2>
    <p>{`Our arrow currently overlaps the reference, we can give it a distance of 8px
using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`offset`}</code>{` modifier:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` popperInstance `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Popper`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`createPopper`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`button`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` tooltip`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  modifiers`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'offset'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      options`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        offset`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Here's the result so far:`}</p>
    <Result3 mdxType="Result3" />
    <h2 {...{
      "id": "functionality",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#functionality",
        "aria-label": "functionality permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Functionality`}</h2>
    <p>{`We only want our tooltip to show when hovering or focusing the button.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`#tooltip`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* ... */`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`#tooltip[data-show]`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` block`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`show`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  tooltip`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setAttribute`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'data-show'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// We need to tell Popper to update the tooltip position`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// after we show the tooltip, otherwise it will be incorrect`}</span>{`
  popperInstance`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`update`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`hide`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  tooltip`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`removeAttribute`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'data-show'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` showEvents `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'mouseenter'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'focus'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` hideEvents `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'mouseleave'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'blur'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

showEvents`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`event`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  button`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`event`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` show`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

hideEvents`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`event`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  button`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`event`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` hide`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Here's the final result:`}</p>
    <Result4 mdxType="Result4" />
    <h2 {...{
      "id": "performance",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#performance",
        "aria-label": "performance permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Performance`}</h2>
    <p>{`Once we create a popper with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`createPopper`}</code>{`, it stays "alive". This means while
scrolling the page, the popper is constantly being updated, even if it is not
visible. We can disable the event listeners when the tooltip is hidden to
optimize it:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`show`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Make the tooltip visible`}</span>{`
  tooltip`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setAttribute`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'data-show'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Enable the event listeners`}</span>{`
  popperInstance`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setOptions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`options`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`options`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    modifiers`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`options`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`modifiers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'eventListeners'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` enabled`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Update its position`}</span>{`
  popperInstance`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`update`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`hide`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Hide the tooltip`}</span>{`
  tooltip`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`removeAttribute`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'data-show'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Disable the event listeners`}</span>{`
  popperInstance`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setOptions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`options`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`options`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    modifiers`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`options`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`modifiers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'eventListeners'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` enabled`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h2 {...{
      "id": "full-code",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#full-code",
        "aria-label": "full code permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Full code`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token doctype"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<!`}</span><span parentName="span" {...{
              "className": "token doctype-tag"
            }}>{`DOCTYPE`}</span>{` `}<span parentName="span" {...{
              "className": "token name"
            }}>{`html`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`html`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`head`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`title`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Popper Tutorial`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`title`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`style`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token style"
          }}><span parentName="span" {...{
              "className": "token language-css"
            }}>{`
      `}<span parentName="span" {...{
                "className": "token selector"
              }}>{`#tooltip`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`background`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` #333`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`color`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` white`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`font-weight`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` bold`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`padding`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` 4px 8px`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`font-size`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` 13px`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`border-radius`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` 4px`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`display`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` none`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
      `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{`

      `}<span parentName="span" {...{
                "className": "token selector"
              }}>{`#tooltip[data-show]`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`display`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` block`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
      `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{`

      `}<span parentName="span" {...{
                "className": "token selector"
              }}>{`#arrow,
      #arrow::before`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`position`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` absolute`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`width`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` 8px`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`height`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` 8px`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`background`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` inherit`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
      `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{`

      `}<span parentName="span" {...{
                "className": "token selector"
              }}>{`#arrow`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`visibility`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` hidden`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
      `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{`

      `}<span parentName="span" {...{
                "className": "token selector"
              }}>{`#arrow::before`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`visibility`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` visible`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`content`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`''`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`transform`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token function"
              }}>{`rotate`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`45deg`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
      `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{`

      `}<span parentName="span" {...{
                "className": "token selector"
              }}>{`#tooltip[data-popper-placement^='top'] > #arrow`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`bottom`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` -4px`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
      `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{`

      `}<span parentName="span" {...{
                "className": "token selector"
              }}>{`#tooltip[data-popper-placement^='bottom'] > #arrow`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`top`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` -4px`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
      `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{`

      `}<span parentName="span" {...{
                "className": "token selector"
              }}>{`#tooltip[data-popper-placement^='left'] > #arrow`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`right`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` -4px`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
      `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{`

      `}<span parentName="span" {...{
                "className": "token selector"
              }}>{`#tooltip[data-popper-placement^='right'] > #arrow`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
        `}<span parentName="span" {...{
                "className": "token property"
              }}>{`left`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` -4px`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
      `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{`
    `}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`style`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`head`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`body`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`button`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`button`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`aria-describedby`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`tooltip`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`My button`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`button`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`tooltip`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`role`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`tooltip`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      My tooltip
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`arrow`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`data-popper-arrow`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`script`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://unpkg.com/@popperjs/core@2`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token script"
          }}></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token script"
          }}><span parentName="span" {...{
              "className": "token language-javascript"
            }}>{`
      `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`const`}</span>{` button `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` document`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`querySelector`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`'#button'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
      `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`const`}</span>{` tooltip `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` document`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`querySelector`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`'#tooltip'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`

      `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`const`}</span>{` popperInstance `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` Popper`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`createPopper`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`button`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` tooltip`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
        modifiers`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span>{`
          `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
            name`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'offset'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
            options`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
              offset`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token number"
              }}>{`0`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token number"
              }}>{`8`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
            `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
          `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
        `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
      `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`

      `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`function`}</span>{` `}<span parentName="span" {...{
                "className": "token function"
              }}>{`show`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
        `}<span parentName="span" {...{
                "className": "token comment"
              }}>{`// Make the tooltip visible`}</span>{`
        tooltip`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`setAttribute`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`'data-show'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`''`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`

        `}<span parentName="span" {...{
                "className": "token comment"
              }}>{`// Enable the event listeners`}</span>{`
        popperInstance`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`setOptions`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token parameter"
              }}>{`options`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
          `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`...`}</span>{`options`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
          modifiers`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span>{`
            `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`...`}</span>{`options`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`modifiers`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
            `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` name`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'eventListeners'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` enabled`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token boolean"
              }}>{`true`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
          `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
        `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`

        `}<span parentName="span" {...{
                "className": "token comment"
              }}>{`// Update its position`}</span>{`
        popperInstance`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`update`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
      `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{`

      `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`function`}</span>{` `}<span parentName="span" {...{
                "className": "token function"
              }}>{`hide`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
        `}<span parentName="span" {...{
                "className": "token comment"
              }}>{`// Hide the tooltip`}</span>{`
        tooltip`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`removeAttribute`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`'data-show'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`

        `}<span parentName="span" {...{
                "className": "token comment"
              }}>{`// Disable the event listeners`}</span>{`
        popperInstance`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`setOptions`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token parameter"
              }}>{`options`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
          `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`...`}</span>{`options`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
          modifiers`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span>{`
            `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`...`}</span>{`options`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`modifiers`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
            `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` name`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'eventListeners'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` enabled`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token boolean"
              }}>{`false`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
          `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{`
        `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
      `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{`

      `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`const`}</span>{` showEvents `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`'mouseenter'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'focus'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
      `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`const`}</span>{` hideEvents `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`'mouseleave'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'blur'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`

      showEvents`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`forEach`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token parameter"
              }}>{`event`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
        button`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`addEventListener`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`event`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` show`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
      `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`

      hideEvents`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`forEach`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token parameter"
              }}>{`event`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=>`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
        button`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`addEventListener`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`event`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` hide`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
      `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
    `}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`body`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`html`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <h2 {...{
      "id": "finished",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#finished",
        "aria-label": "finished permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Finished`}</h2>
    <p>{`You've now created a basic tooltip using Popper! Of course, there is more you
can do, such as adding animations or interactivity. These are up to you to
explore when creating abstractions for common popper elements like tooltips,
popovers, drop-downs, and more.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      